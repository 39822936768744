import { Dispatch } from "redux";

import {
  CHECK_CREDENTIALS,
  CHECK_CREDENTIALS_FAILURE,
  CHECK_CREDENTIALS_SUCCESS,
  CLEAR_ACCOUNT_ERROR,
  ENABLE_2FA,
  LOGIN_USER,
  LOGIN_USER_FAILURE,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_FAILURE,
  LOGOUT_USER_SUCCESS,
  REFRESH_TOKEN,
  REFRESH_TOKEN_FAILURE,
  REFRESH_TOKEN_SUCCESS,
  SET_2FA_STATUS,
  SET_REMEMBER_ME,
} from "../constants";
import { AccountActionTypes } from "../types/accountActionTypes";
import { login } from "../../helpers/api/api";
import accountService from "../../services/AccountService";
import { CheckUserCredentialsResponse } from "../../model/account/checkUserCredentialsResponse";

export function checkCredentials(): AccountActionTypes {
  return {
    type: CHECK_CREDENTIALS,
  };
}

export function checkCredentialsSuccess(
  data: CheckUserCredentialsResponse
): AccountActionTypes {
  return {
    type: CHECK_CREDENTIALS_SUCCESS,
    payload: data,
  };
}

export function checkCredentialsError(error: string): AccountActionTypes {
  return {
    type: CHECK_CREDENTIALS_FAILURE,
    payload: error,
  };
}

export function loginUser(): AccountActionTypes {
  return {
    type: LOGIN_USER,
  };
}

export function loginUserSuccess(data: any): AccountActionTypes {
  return {
    type: LOGIN_USER_SUCCESS,
    payload: data,
  };
}

export function loginUserError(error: string): AccountActionTypes {
  return {
    type: LOGIN_USER_FAILURE,
    payload: error,
  };
}

export function authenticateUser(email: string, password: string) {
  return async function action(dispatch: Dispatch) {
    dispatch(loginUser());
    return login(email, password)
      .then((response: any) => {
        dispatch(loginUserSuccess(response));
      })
      .catch((error) => {
        dispatch(loginUserError(error.body.message));
      });
  };
}

export function refreshTokenBegin(): AccountActionTypes {
  return {
    type: REFRESH_TOKEN,
  };
}

export function refreshTokenSuccess(data: any): AccountActionTypes {
  return {
    type: REFRESH_TOKEN_SUCCESS,
    payload: data,
  };
}

export function refreshTokenError(error: string): AccountActionTypes {
  return {
    type: REFRESH_TOKEN_FAILURE,
    payload: error,
  };
}

export function logoutUser(): AccountActionTypes {
  return {
    type: LOGOUT_USER,
  };
}

export function logoutUserSuccess(): AccountActionTypes {
  return {
    type: LOGOUT_USER_SUCCESS,
  };
}

export function logoutUserError(error: string): AccountActionTypes {
  return {
    type: LOGOUT_USER_FAILURE,
    payload: error,
  };
}

export function logoutAndRevokeToken() {
  return function action(dispatch: Dispatch) {
    dispatch(logoutUser());
    return accountService
      .revokeToken()
      .then((response: any) => {
        dispatch(logoutUserSuccess());
      })
      .catch((error: any) => {
        dispatch(logoutUserError(error.body.message));
      });
  };
}

export function logout() {
  return function action(dispatch: Dispatch) {
    dispatch(logoutUserSuccess());
  };
}

export function twoFactorAuthSetupCompletedAndEnabled(): AccountActionTypes {
  return {
    type: ENABLE_2FA,
  };
}

export function setRememberMe(data: boolean): AccountActionTypes {
  return {
    type: SET_REMEMBER_ME,
    payload: data,
  };
}

export function set2faStatus(data: boolean): AccountActionTypes {
  return {
    type: SET_2FA_STATUS,
    payload: data,
  };
}

export function clearAccountError(): AccountActionTypes {
  return {
    type: CLEAR_ACCOUNT_ERROR,
  };
}
