import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";

import accountService from "../../services/AccountService";
import ErrorDisplay from "../ErrorHandling/ErrorDisplay";
import {
  selectAccountError,
  selectAccountIsLoading,
  selectAccountRememberMe,
} from "../../redux/selectors/accountSelectors";
import {
  checkCredentials,
  checkCredentialsError,
  checkCredentialsSuccess,
  clearAccountError,
  loginUser,
  loginUserError,
  loginUserSuccess,
  setRememberMe,
} from "../../redux/actions/accountActions";
import { useAppThunkDispatch } from "../../redux/configureStore";
import { login } from "../../helpers/api/api";
import { CheckUserCredentialsResponse } from "../../model/account/checkUserCredentialsResponse";
import { AccountTypeId } from "../../model/account/accountTypeId";

const useStyles = makeStyles((theme: any) => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  username: {
    float: "left",
  },
  password: {
    float: "right",
  },
  textField: {
    "& .Mui-focused legend": {
      paddingRight: 20,
    },
  },
  passwordField: {
    "& .Mui-focused legend": {
      paddingRight: 10,
    },
  },
}));

const LoginForm: React.VoidFunctionComponent = () => {
  const classes = useStyles();
  let navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const isLoading = useSelector(selectAccountIsLoading);
  const rememberMe = useSelector(selectAccountRememberMe);
  const error = useSelector(selectAccountError);
  const dispatch = useAppThunkDispatch();

  const onSubmit = async (e: any) => {
    e.preventDefault();
    dispatch(clearAccountError());
    dispatch(checkCredentials());
    accountService
      .checkCredentials(username, password)
      .then((response: CheckUserCredentialsResponse) => {
        //TODO: This is a bit of a hack, the password needs the be stored to use after the pin entry
        response.email = username;
        response.password = password;

        dispatch(checkCredentialsSuccess(response));

        if (!response.isTwoFactorAuthenticationEnabled) {
          dispatch(loginUser());
          login(username, password)
            .then((response: any) => {
              dispatch(loginUserSuccess(response));
              if (
                response.accountType.accountTypeId ===
                AccountTypeId.HealthcareProfessional
              ) {
                navigate("/dashboard/patients");
              } else {
                navigate(`/dashboard/patients/${response.accountGuid}`);
              }
            })
            .catch((error) => {
              dispatch(loginUserError(error.body.message));
            });
        }
      })
      .catch((error) => {
        dispatch(checkCredentialsError(error.body));
      });
  };

  const onForgotPassword = (e: any) => {
    e.preventDefault();
    navigate("/forgot-password");
  };

  return (
    <>
      <Typography variant="h4">
        <strong>Log in</strong>
      </Typography>
      <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={onSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="username"
          autoComplete="username"
          autoFocus
          onChange={(e) => setUsername(e.target.value)}
          className={classes.textField}
          error={error !== null}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={(e) => setPassword(e.target.value)}
          className={classes.passwordField}
          error={error !== null}
        />
        {error !== null && <ErrorDisplay error={error} />}

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="rememberMe"
                color="primary"
                checked={rememberMe}
                onChange={(e) => dispatch(setRememberMe(!rememberMe))}
              />
            }
            label="Keep me logged in"
          />
        </Grid>

        <LoadingButton
          type="submit"
          loading={isLoading}
          variant="contained"
          color="primary"
          className={classes.submit}
          sx={{ mt: 3 }}
        >
          Login
        </LoadingButton>

        <Grid container>
          <Grid item xs sx={{ mt: 4 }}>
            <Link
              sx={{ cursor: "pointer" }}
              variant="body2"
              onClick={onForgotPassword}
            >
              Forgot password?
            </Link>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default LoginForm;
