import merge from "lodash/merge";
import { store } from "../../redux/configureStore";
import {
  refreshTokenBegin,
  refreshTokenSuccess,
  refreshTokenError,
} from "../../redux/actions/accountActions";
import { showError, clearError } from "../../redux/actions/errorActions";
import { handleResponse } from "../handleResponse";
import { toastService } from "../../services/ToastService/toastService";
import { isDevelopment } from "../utilityFunctions/utilityFunctions";

import { configureRefreshFetch, fetchJSON } from "../../modules/refreshFetch";

const baseUrl = `${process.env.REACT_APP_API_URL}`;

const fetchJSONWithToken = (url, options = {}) => {
  const token = retrieveToken();

  let optionsWithToken = options;
  if (token != null) {
    optionsWithToken = merge({}, options, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  return fetchJSON(url, optionsWithToken);
};

//this is here for now as it's being used as part of the setup for token refresh - at some point it might be nice to have this in account service
const login = (email, password, twoFactorAuthenticationCode) => {
  return fetchJSON(`${baseUrl}/accounts/authenticate`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({
      email,
      password,
      twoFactorAuthenticationCode,
    }),
  }).then((response) => {
    const handle = handleResponse(response);
    handle.then((data) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      const state = store.getState();
      data.rememberMe = state.account.account.rememberMe;
      data.isTwoFactorAuthenticationPassed =
        state.account.account.isTwoFactorAuthenticationPassed;
      localStorage.setItem("account", JSON.stringify(data));
    });
    return handle;
  });
};

const logout = () => {
  store.dispatch(clearError());
  window.location.href = "/";
  localStorage.removeItem("account");
};

const shouldRefreshToken = (error) => {
  if (error.response.status === 401) {
    if (isDevelopment()) {
      toastService.showErrorToast("Error 401: Unauthorised");
    }
    return true;
  }
  return false;
};

const refreshToken = () => {
  store.dispatch(refreshTokenBegin());
  return fetchJSON(`${baseUrl}/accounts/refresh-token`, {
    method: "POST",
    credentials: "include",
  })
    .then((response) => {
      store.dispatch(refreshTokenSuccess(response.body));
      const state = store.getState();
      localStorage.setItem("account", JSON.stringify(state.account.account));
    })
    .catch((error) => {
      store.dispatch(refreshTokenError(error));
      if (
        error.response.status === 400 &&
        error.body.message === "Invalid token"
      ) {
        store.dispatch(showError(error.body.message));
      } else if (error.response.status === 500) {
        store.dispatch(showError(error.body.message));
      }
      throw error; // Ensure the error is thrown to be caught by fetchJSON
    });
};

const fetch = configureRefreshFetch({
  fetch: fetchJSONWithToken,
  shouldRefreshToken,
  refreshToken,
});

const retrieveToken = () => {
  const state = store.getState();
  return state.account.account.jwtToken;
};

export { fetch, login, logout, refreshToken };
