import { fetch } from "../helpers/api/api";
import { handleResponse } from "../helpers/handleResponse";
import { PatientHealthcareProfessionalAssignRequest } from "../model/patient/patientHealthcareProfessionalAssignRequest";
import { PatientHealthcareProfessionalUnassignRequest } from "../model/patient/PatientHealthcareProfessionalUnassignRequest";

const baseUrl = `${process.env.REACT_APP_API_URL}/patient`;

export const patientService = {
  getPatients,
  assignHCP,
  unassignHCP,
};

function getPatients(
  accountId: string,
  isAuthorized: boolean,
  showActive?: boolean
) {
  return fetch(
    `${baseUrl}/get-patients/${accountId}/${isAuthorized}/${
      !showActive ? true : false
    }`
  )
    .then((response: any) => handleResponse(response))
    .catch((error: any) =>
      handleResponse({ response: error.response, body: error.body })
    );
}

function assignHCP(
  hcpAccountId: string,
  isAuthorizedByPatient: boolean,
  patientID: string
) {
  const request: PatientHealthcareProfessionalAssignRequest = {
    healthcareProfessionalAccountGuid: hcpAccountId,
    isAuthorizedByPatient: isAuthorizedByPatient,
    patientAccountGuid: patientID,
  };

  const requestOptions = {
    method: "POST",
    body: JSON.stringify(request),
  };
  return fetch(`${baseUrl}/assign-hcp`, requestOptions)
    .then((response: any) => handleResponse(response))
    .catch((error: any) =>
      handleResponse({ response: error.response, body: error.body })
    );
}

function unassignHCP(
  hcpAccountId: string,
  isAuthorizedByPatient: boolean,
  patientID: string
) {
  const request: PatientHealthcareProfessionalUnassignRequest = {
    healthcareProfessionalAccountGuid: hcpAccountId,
    isAuthorizedByPatient: isAuthorizedByPatient,
    patientAccountGuid: patientID,
  };
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(request),
  };
  return fetch(`${baseUrl}/unassign-hcp`, requestOptions)
    .then((response: any) => handleResponse(response))
    .catch((error: any) =>
      handleResponse({ response: error.response, body: error.body })
    );
}
